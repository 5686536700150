import * as React from "react";
import { 
  CircularProgress, 
  Typography
} from "@mui/material";

export default function GradientCircularProgress(props) {
  return (
    <>
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#ffdcb5" />
            <stop offset="50%" stopColor="#ff8f10" />
            <stop offset="100%" stopColor="#f05a28" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        size={80} 
        sx={{ 
          "svg circle": { stroke: "url(#my_gradient)" }, 
          '& .MuiCircularProgress-circle': {
            strokeWidth: 4, // ปรับความหนาเป็น 10px
          },
          mb: 4
        }}
      />
    </React.Fragment>
    <Typography id="modal-modal-description" name={props.name} sx={{ color: props.text ? null : "transparent" }}>
      { props.text ||"กำลังดึงข้อมูล" }
    </Typography>
    </>
  );
}
