const config = {
  env: process.env.NODE_ENV || "",
  cdp: {
    apiBaseUrl: process.env.REACT_APP_CDP_API_BASE_URL || "",
    projectId: process.env.REACT_APP_CDP_PROJECT_ID || "",
    authBaseUrl: process.env.REACT_APP_CDP_AUTH_API_BASE_URL || "",
    projectBaseUrl: process.env.REACT_APP_CDP_PROJECT_API_BASE_URL || "",
    apiPdpaUrl: process.env.REACT_APP_API_PDPA_URL || "https://pdpa-api-dev.ex10.tech/v1",
    apiUploadUrl: process.env.REACT_APP_API_UPLOAD_URL || "https://ex10-cdp-upload-api-dev.ex10.tech/v1",
    apiLinkUrl: process.env.REACT_APP_API_LINK_URL || "https://link-dev.ex10.tech/v1",
    liffWebUrl: process.env.REACT_APP_CDP_LIFF_WEB_URL || "",
  },
};

export default config;
