import axios from "axios";
import config from "../config";
class Ex10Api {
  async getChannelById(channelId) {
    return axios.get(
      `${config.cdp.projectBaseUrl}/projects/channels/${channelId}/public`
    );
  }

  async getLIFFChannelDetailByChannelId(channelId) {
    return axios.get(
      `${config.cdp.projectBaseUrl}/projects/channels/liff`,
      {
        headers: {
          "x-api-key" : `${channelId}`
        },
      }
    );
  }


  async getPdpaByUserId(channelId, lineUserId) {
    return axios.get(
      `${config.cdp.apiPdpaUrl}/liff/pdpa/${channelId}/${lineUserId}`
    );
  }

  async allowPdpaByUserId(channelId, lineUserId, pdpaId, data) {
    return axios.post(
      `${config.cdp.apiPdpaUrl}/liff/pdpa/${channelId}/${pdpaId}/${lineUserId}`, 
      {
        "actions": data,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  async uploadFlie(data) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return axios.post(`${config.cdp.apiUploadUrl}/public/file`, data, {
      headers: headers,
    });
  }

  async clickEndpointLinkByShortIdAndUtmNo(data) {
    const headers = {
      "Content-Type": "application/json",
    };
    return axios.post(`${config.cdp.apiLinkUrl}/e/link/click`, data, {
      headers: headers,
    });
  }

  async useRewardId(channelId, liffIdToken, rewardId, userId) {
    const Authorization = `Bearer ${liffIdToken}`;
    return axios.post(
      `https://sme-d-bank-dev-api.ex10.space/api/crm-api/crm/api/v1/digital-rewards/markuse/reward-id/${rewardId}/line-user-id/${userId}`,
      {},
      {
        headers: {
          "x-api-key" : `${channelId === "2005564202" ? "UzIwMjQwNjExSzdZMzM=" : "UzIwMjQwNjA3Q1Y1QkM="}`,
          Authorization
        },
      }
    );
  }

  async getLinkDataEndUser(shortId) {
    const headers = {
      "Content-Type": "application/json",
    };
    return axios.get(`${config.cdp.apiLinkUrl}/e/link/${shortId}`, {
      headers: headers,
    });
  }
}

const ex10Api = new Ex10Api();

export default ex10Api;
