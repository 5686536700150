import { 
  Suspense, 
  useEffect, } from "react";
import { LinearProgress } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "../src/routes";
// import LineUtil from "./utils/LineUtil";

export default function App() {
  useEffect(() => {
    // async function initLiffApp() {
    //   if (localStorage.getItem('survey-LIFFAPPS')) {
    //     try {
    //       await LineUtil.init(true);
    //       console.log("InitLiffApp Successfully ✨🎉");
    //     } catch (error) {
    //       console.log(`Error ⚠️ : ${error} `);
    //     }
    //   }
    // }
    // initLiffApp();
  });

  return (
    <Suspense fallback={<LinearProgress />}>
      <BrowserRouter>
        <Routes>
          {routes.map(({ path, element: Element }, index) => (
            <Route key={index} path={path} element={Element} />
          ))}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
