import config from "../config";
import liff from "@line/liff";

function getLiffAppId() {
  const pathname = window.location.pathname
  const liffId = JSON.parse(localStorage.getItem('survey-LIFFAPPS')) || []

  const LINK_CLICK_LIFF = liffId.find(LiffApp => LiffApp.liffAppName === "WEB_LIFF_ID") || { liffAppName: "WEB_LIFF_ID", liffId: config.cdp.liffWebUrl }
  console.log(pathname, LINK_CLICK_LIFF.liffId)

  switch (true) {
    case pathname.includes('/link'):
      return LINK_CLICK_LIFF.liffId
    case pathname.includes('/survey'):
      return LINK_CLICK_LIFF.liffId
    default:
      return LINK_CLICK_LIFF.liffId
  }
}

let isInit = false;

class LineUtil {
  async init(isRedirect) {
    return new Promise((resolve, reject) => {
      console.log('Init: ', isInit);
      if (`${config.env}` === "development") {
        resolve();
        return;
      } 

      let redirectUri = "";

      if (isRedirect) {
        redirectUri = `${window.location.origin}${window.location.pathname}${window.location.search}`;
      }

      try {
        if (!isInit) {
          
          const liffId = getLiffAppId()
          liff.init({ liffId });

          liff.ready.then(() => {
            isInit = true;

            if (liff.isInClient()) {
              // isInit = true;
              resolve();
            } else {
              if (!liff.isLoggedIn()) {
                if (redirectUri) {
                  liff.login({ redirectUri });
                } else {
                  liff.login();
                }
              } else {
                // isInit = true;
                resolve();
              }
            }
          }).catch((error)=>{
            console.log("liff.ready error: ", error);
          });

          if (localStorage.getItem('survey-LIFFAPPS') === null) {
            resolve();
            return;
          }
        } else {
          resolve();
        }
      } catch (error) {
        console.log("LineUtil.init -> error: ", error);
        reject();
      }
    });
  }

  async getProfile() {
    // if (!isInit) await this.init();
    let profile;
    if (`${config.env}` === "development") {
      profile = {
        displayName: "Book",
        pictureUrl: "https://profile.line-scdn.net/0hDCGRmDyMG2xqKw5G8wplExp7GAZJWkJ-RUQAC1x7FQxXSVgzE0hcWlh_TFgASFVpQU8ADF57R1lmOGwKdH3nWG0bRVtTHV8_QUtQjA",
        userId: "U86ce62cfc2e637feea65efc19f14c7bc",
      };
      localStorage.setItem("lineProfile", JSON.stringify(profile));
    } else {
      profile = await liff.getProfile();
      localStorage.setItem("lineProfile", JSON.stringify(profile));
    }
    return profile;
  }

  closeWindow() {
    liff.closeWindow();
  }

  openWindow(url, external) {
    liff.openWindow({ url, external });
  }

  async sendMessages(messages) {
    const messagesToSend = Array.isArray(messages) ? messages : [messages];
    liff.sendMessages(messagesToSend);
  }
}

const lineUtil = new LineUtil();
export default lineUtil;
