import { 
  Grid, 
  Typography, 
  // Button,
} from "@mui/material";

const Thankyou = () => {
  //   const [channel, setChannel] = useState({});

  //   const handleClickLink = () => {
  //     if (channel.channelId) {
  //       window.location.replace(`https://line.me/ti/p/${channel.channelId}`)
  //     }
  //   }

  //   const getChannelById = async () => {
  //     const surveyInfo = JSON.parse(localStorage.getItem("survey-info"));
  //     try {
  //       const res = await ex10Api.getChannelById(surveyInfo.channelId);
  //       const { data } = res.data;
  //       if (data) {
  //         setChannel(data)
  //         console.log({ data });
  //       }
  //     } catch (err) {
  //       console.log("getChannelById error:", err);
  //     }
  //   };

  //   useEffect(() => {
  //     getChannelById()
  //   },[]);

  return (
    <>
      <Grid
        spacing={2}
        container
        direction="row"
        className="error-page-container"
      >
        <Grid item xs={12}>
          <Typography sx={{ color: "#7d8391" }} variant="h6">
            กิจกรรมเสร็จสิ้นแล้ว
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Thankyou;
