import React from "react";
import {
  Grid,
  Typography
} from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const Error = () => {
  return (
    <>
      <Grid spacing={2} container direction="row" className="error-page-container">
        <Grid item xs={12}>
          <WarningAmberIcon color="action" fontSize="large" />
          <Typography sx={{ mt: 2, color: '#7d8391' }} variant="h6">Error 404</Typography>
          <Typography sx={{ mt: 1 }} variant="h5">Oops! We couldn't find that page.</Typography>
          <Typography sx={{ mt: 1, color: '#7d8391' }} variant="body1">The page you're looking for doesn't exist. Please check the address and try again.</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Error;
